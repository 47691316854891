import "./set-public-path.js";
import "./tailwind.scss";
import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () =>
    import(/* webpackChunkName: "root" */ "./root.js").then((mod) => mod.Root),
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: "forms-ui",
  mainContentTransition: false,
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

function domElementGetter() {
  const formsUi = document.getElementById("forms-ui");
  if (formsUi) return formsUi;

  // If the element doesn't exist then create it
  const container = document.createElement("div");
  container.setAttribute("id", "forms-ui");
  document.body.appendChild(container);
  return container;
}

export function getFormViewer() {
  return import(
    /* webpackChunkName: "FormViewer" */ "./exports/form-viewer-export.component.tsx"
  );
}

export function getSendQnrModal() {
  return import(
    /* webpackChunkName: "SendQnrModal" */ "./exports/send-qnr-modal-export.tsx"
  );
}

export function getQnrTaskModal() {
  return import(
    /* webpackChunkName: "QnrTaskModal" */ "./exports/qnr-task-modal-export.tsx"
  );
}
